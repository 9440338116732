import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { getUserType } from "../../utils/helpers";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/product/", state: "productmenu" },
      { path: "/shop/", state: "shopmenu" },
      { path: "/user/", state: "usermenu" },
      { path: "/expense/", state: "expensemenu" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas shadow-lg" id="sidebar">
        <ul className="nav">
          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i className="ti-home menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/user") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.usermenu ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("usermenu")}
              data-toggle="collapse"
            >
              <i className="ti-eraser menu-icon"></i>
              <span className="menu-title">Users</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.usermenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/user/user-management")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/user/user-management"
                  >
                    Users
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive("/product") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.productmenu ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("productmenu")}
              data-toggle="collapse"
            >
              <i className="ti-eraser menu-icon"></i>
              <span className="menu-title">Product</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.productmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/product/product-management")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/product/product-management"
                  >
                    Products
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/shop") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.shopmenu ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("shopmenu")}
              data-toggle="collapse"
            >
              <i className="ti-eraser menu-icon"></i>
              <span className="menu-title">Shops</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.shopmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/shop/shop-management")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/shop/shop-management"
                  >
                    Shops
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/expense") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.expensemenu ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("expensemenu")}
              data-toggle="collapse"
            >
              <i className="ti-eraser menu-icon"></i>
              <span className="menu-title">Expense</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.expensemenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/expense/expense-management")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/expense/expense-management"
                  >
                    Expense
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
    
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
