import jsPDF from "jspdf";
import autoTable, { RowInput } from 'jspdf-autotable'
const exportPDF = (isdownload, reportTitle ,columns , records, orientation) => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4
    const pdfOrientation = orientation; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(pdfOrientation, unit, size);
    doc.setFontSize(15);
    let cols = [];

    columns.forEach(column => {
        cols.push(column.title)
    });
    
      //  cols.pop()
    const title = "";
    const headers = [cols];

    const data = records

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, {
      head: headers,
      body: data,
      startY : 50,
      didDrawCell: (data) => { },
  });




    // doc.autoTable(content);
    if(isdownload === "no"){
      doc.save("report.pdf")
    }
    else if (isdownload === "print"){
      doc.setFontSize(22);
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    }
    else{
     doc.output('dataurlnewwindow');
    }

  
  }
  export default exportPDF;