import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import { getUserType } from "../utils/helpers";

const Login = lazy(() => import("./user-pages/Login"));
const SignUp = lazy(() => import("./user-pages/SignUp"));
const Dashboard = lazy(() => import("./Dashboard/Dasboard"));
const Product = lazy(() => import("./Product/Product"));
const AddProduct = lazy(() => import("./Product/AddProduct"));
const EditProduct = lazy(() => import("./Product/EditProduct"));

const Shop = lazy(() => import("./Shop/Shop"));
const AddShop = lazy(() => import("./Shop/AddShop"));
const EditShop = lazy(() => import("./Shop/EditShop"));
const Invoice = lazy(() => import("./Shop/Invoice"));
const Record = lazy(() => import("./Shop/Record"));
const User = lazy(() => import("./User/User"));
const AddUser = lazy(() => import("./User/AddUser"));
const EditUser = lazy(() => import("./User/EditUser"));
const Expense = lazy(() => import("./Expense/Expense"));
const AddExpense = lazy(() => import("./Expense/AddExpense"))
class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
        </Switch>
        {/* {localStorage?.getItem("token") ? ( */}
          <Switch>
            {/* <Route exact path="/backoffice/dashboard" component={ Dashboard } /> */}

          <Route path="/login" component={Login} />
          <Route path="/dashboard" component={Dashboard} />

          <Route path="/product/product-management" component={Product} />
          <Route path="/product/add-product" component={AddProduct} />
          <Route path="/product/edit-product/:id" component={EditProduct} />

          <Route path="/shop/shop-management" component={Shop} />
          <Route path="/shop/add-shop" component={AddShop} />
          <Route path="/shop/edit-shop/:id" component={EditShop} />
          <Route path="/shop/invoice/:id" component={Invoice} />
          <Route path="/shop/record/:id" component={Record} />

          <Route path="/user/user-management" component={User} />
          <Route path="/user/add-user" component={AddUser} />
          <Route path="/user/edit-user/:id" component={EditUser} />

          <Route path="/expense/expense-management" component={Expense} />
          <Route path="/expense/add-expense" component={AddExpense} />

          </Switch>
          {/* {localStorage?.getItem("token") ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/login" />
            )}
       
        {/* ) : (
          <Redirect to="/login" />
        )} */}
      </Suspense>
    );
  }
}

export default AppRoutes;
